.product-form {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;

  h2 {
    margin-bottom: 2rem;
    color: #333;
    font-size: 1.5rem;
  }

  &__error {
    margin-bottom: 1.5rem;
    padding: 1rem;
    background-color: #fff5f5;
    border: 1px solid #fc8181;
    border-radius: 4px;
    color: #c53030;
    font-size: 0.875rem;
  }

  .form-group {
    margin-bottom: 1.5rem;

    label {
      display: block;
      margin-bottom: 0.5rem;
      font-weight: 500;
      color: #333;

      &::after {
        content: ' *';
        color: #c53030;
      }

      &:not([required])::after {
        content: '';
      }
    }

    input,
    select,
    textarea {
      width: 100%;
      padding: 0.75rem;
      border: 1px solid #ddd;
      border-radius: 4px;
      font-size: 1rem;
      transition: all 0.2s ease;

      &:focus {
        outline: none;
        border-color: #0066cc;
        box-shadow: 0 0 0 2px rgba(0, 102, 204, 0.1);
      }

      &.error {
        border-color: #fc8181;
        background-color: #fff5f5;

        &:focus {
          border-color: #c53030;
          box-shadow: 0 0 0 2px rgba(197, 48, 48, 0.1);
        }
      }

      &:disabled {
        background-color: #f5f5f5;
        cursor: not-allowed;
        opacity: 0.7;
      }
    }

    .ql-container {
      height: 200px;
    }
  }

  .ProductForm__section {
    margin-bottom: 2rem;
    padding: 1.5rem;
    background-color: #f8f9fa;
    border-radius: 4px;

    h3 {
      margin-bottom: 1rem;
      color: #333;
      font-size: 1.25rem;
    }
  }

  .CocktailForm {
    &__row {
      display: flex;
      gap: 1rem;
      margin-bottom: 1rem;
    }

    &__field {
      flex: 1;
    }

    &__field_title {
      margin-bottom: 0.5rem;
      font-weight: 500;
      color: #333;
    }

    &__input {
      width: 100%;
      padding: 0.75rem;
      border: 1px solid #ddd;
      border-radius: 4px;
      font-size: 1rem;

      &[type="file"] {
        padding: 0.5rem;
        background-color: white;
        cursor: pointer;

        &::-webkit-file-upload-button {
          padding: 0.5rem 1rem;
          margin-right: 1rem;
          background-color: #0066cc;
          color: white;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          transition: background-color 0.2s ease;

          &:hover {
            background-color: #0052a3;
          }
        }
      }
    }
  }

  .button-group {
    display: flex;
    gap: 1rem;
    margin-top: 2rem;

    button {
      padding: 0.75rem 1.5rem;
      border: none;
      border-radius: 4px;
      font-size: 1rem;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.2s ease;

      &.primary {
        background-color: #0066cc;
        color: white;

        &:hover {
          background-color: #0052a3;
        }

        &:disabled {
          background-color: #ccc;
          cursor: not-allowed;
        }
      }

      &.secondary {
        background-color: #f8f9fa;
        color: #333;
        border: 1px solid #ddd;

        &:hover {
          background-color: #e9ecef;
        }
      }
    }
  }
}

.ProductForm {
  width: 75%;
  border: 2px solid #CCCCCC;
  margin: 30px;
  border-radius: 30px;
  padding: 30px;
  &__select-wrapper {
    width: 95%;
  }

  &__header {
    display: flex;
    justify-content: space-between;

    &__buttons {
      display: flex;
      gap: 10px;
      height: 35px;
    }

    &__button {
      padding: 5px 15px;
      border-radius: 10px;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;

      &--cancel {
        background-color: white;
        color: black;
        border: 2px solid black;
      }

      &--save {
        background-color: #0083FD;
        color: white;
        border: 1px solid #0083FD;
        width: 90px;
      }
    }
  }

  &__title {
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  &__section {
    display: flex;
    flex-direction: column;


  }
  &__row {
    display: flex;
    flex-wrap: wrap;
  }
  &__field {
    flex: 1 1 calc(30% - 20px); 
    display: flex;
    gap: 10px;
    flex-direction: column;

  }

  &__field_title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  &__input {
    width: 90%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    font-size: 1em;
  }
  &__select {
    width: 95%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    font-size: 1em;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}

.ProductForm__field--long {
  width: 97.5%;
}
.ProductForm__field--description {
  width: 106%;
  textarea {
    height: 100px;
  }
}



.ProductForm--checkbox {
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
}

.ProductForm__field--checkbox {
  display: flex;
  align-items: center;
}

.ProductForm__field_title {
  margin-right: 10px;
  font-size: 16px;
  font-weight: 400;
}

.ProductForm__input--checkbox {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 4px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
}

.ProductForm__input--checkbox:checked {
  background-color: #007bff;
  border-color: #007bff;
}

.ProductForm__input--checkbox:checked::before {
  content: '';
  position: absolute;
  left: 5px;
  top: 2px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
