.TeamMemberForm {
  width: 75%;
  border: 2px solid #CCCCCC;
  margin: 30px;
  border-radius: 30px;
  padding: 30px;

  &__select-wrapper {
    width: 95%;
  }

  &__header {
    display: flex;
    justify-content: space-between;

    &__buttons {
      display: flex;
      gap: 10px;
      height: 35px;
    }

    &__button {
      padding: 5px 15px;
      border-radius: 10px;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;

      &--cancel {
        background-color: white;
        color: black;
        border: 2px solid black;
      }

      &--save {
        background-color: #0083FD;
        color: white;
        border: 1px solid #0083FD;
        width: 90px;
      }
    }
  }

  &__title {
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  &__section {
    display: flex;
    flex-direction: column;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
  }

  &__field {
    flex: 1 1 calc(30% - 20px); 
    display: flex;
    gap: 10px;
    flex-direction: column;
  }

  &__field_title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  &__input {
    width: 90%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    font-size: 1em;
  }

  &__select {
    width: 95%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    font-size: 1em;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}

.TeamMemberForm__field--long {
  width: 97.5%;
}

.TeamMemberForm__field--description {
  width: 106%;
  textarea {
    height: 100px;
  }
}

.TeamMemberForm--checkbox {
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
}

.TeamMemberForm__field--checkbox {
  display: flex;
  align-items: center;
}

.TeamMemberForm__field_title {
  margin-right: 10px;
  font-size: 16px;
  font-weight: 400;
}

.TeamMemberForm__input--checkbox {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 4px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
}

.TeamMemberForm__input--checkbox:checked {
  background-color: #007bff;
  border-color: #007bff;
}

.TeamMemberForm__input--checkbox:checked::before {
  content: '';
  position: absolute;
  left: 5px;
  top: 2px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
