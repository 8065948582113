.lead-list {
  margin: 40px;
  
  h1 {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  &__container {
    width: 100%;
    overflow-x: auto;
  }

  &__table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 20px;
    margin-bottom: 20px;
    thead {
      tr {
        th {
          background-color: #333;
          color: #fff;
          padding: 10px;
          text-align: left;
          &:first-child {
            border-top-left-radius: 10px;
          }
          &:last-child {
            border-top-right-radius: 10px;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 10px;
          border-top: 1px solid #ddd;
          color: #808080;
          ;
          &:last-child {
            text-align: left; 
          }
          &:first-child {
            color: black;
          }
        }

        &:nth-child(even) {
          background-color: #f9f9f9;
        }
      }
    }
  }

  .contact-button {
    background-color: #333;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 35px;
    display: inline-flex;
    justify-content: flex-start;
    &:hover {
      background-color: #555;
    }
  }

  /* Additional styles for dropdown button */
  .dropdown-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: relative;

    &:hover .dropdown-content {
      display: block;
    }
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 5px;

    button {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      width: 100%;
      text-align: left;
      border: none;
      background: none;
      cursor: pointer;

      &:hover {
        background-color: #f1f1f1;
      }
    }
  }
}
