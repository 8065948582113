.toggle-container {
  display: flex;
  background-color: #0083FD;
  border-radius: 41.93px;
  padding: 4px;
  margin: 20px auto;
  width: fit-content;
  position: relative;
  min-width: 300px;
  height: 48px;
  box-sizing: border-box;

  .toggle-option {
    padding: 8px 24px;
    cursor: pointer;
    border-radius: 40px;
    transition: all 0.3s ease;
    font-weight: 500;
    font-family: 'DM Sans', sans-serif;
    font-size: 17px;
    line-height: 32px;
    z-index: 1;
    flex: 1;
    text-align: center;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    
    &.active {
      color: #0083FD;
      font-weight: 600;
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    width: calc(50% - 4px);
    height: calc(100% - 8px);
    background-color: white;
    border-radius: 40px;
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }

  &.buyer-active::after {
    transform: translateX(calc(100% + 4px));
  }

  @media (max-width: 768px) {
    min-width: 260px;
    height: 44px;

    .toggle-option {
      font-size: 15px;
      line-height: 28px;
      padding: 6px 20px;
    }
  }
}