.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  background-color: #2D2D2A;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: all 0.3s ease;

  &__white {
    background-color: #FFFFFF;
  }

  &__logo-container {
    flex: 0 0 auto;
  }

  &__logo {
    height: 40px;
    width: auto;

    &__white {
      filter: brightness(0);
    }
  }

  &__nav {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;
  }

  &__block {
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__auth-buttons {
    display: flex;
    gap: 1rem;
  }

  &__auth-button {
    padding: 0.5rem 1rem;
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: transparent;
    color: #FFFFFF;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background: #FFFFFF;
      color: #000000;
    }

    &__white {
      border-color: #000000;
      color: #000000;

      &:hover {
        background: #000000;
        color: #FFFFFF;
      }
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
    transition: opacity 0.2s ease;
    filter: brightness(100);

    &:hover {
      opacity: 0.8;
    }

    &__white {
      filter: brightness(0);
    }
  }

  &__menu-line {
    width: 20px;
    height: 2px;
    filter: brightness(100);
    transition: all 0.3s ease;

    &__white {
      filter: brightness(0);
    }
  }

  &__user {
    display: flex;
    align-items: center;
    gap: 1rem;

    &__menu {
      display: flex;
      flex-direction: column;
      gap: 4px;
      cursor: pointer;
      padding: 4px;

      img {
        width: 20px;
        height: 2px;
      }
    }

    &__avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__dropdown {
    position: absolute;
    top: 100%;
    right: 2rem;
    background: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 0.5rem 0;
    min-width: 200px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    li {
      padding: 0.5rem 1rem;
      cursor: pointer;

      &:hover {
        background: #F3F4F6;
      }

      a {
        color: #1F2937;
        text-decoration: none;
        display: block;
      }
    }
  }

  &__cart {
    position: relative;

    &-link {
      color: #ffffff;
      text-decoration: none;

      &__white {
        color: #2D2D2A;
      }
    }

    &-icon {
      width: 24px;
      height: 24px;

      &__white {
        stroke: #2D2D2A;
      }
    }

    &-count {
      position: absolute;
      top: -8px;
      right: -8px;
      background: #6366F1;
      color: #ffffff;
      font-size: 12px;
      font-weight: bold;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__search-results {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #FFFFFF;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 8px 8px;
    max-height: 80vh;
    overflow-y: auto;
    z-index: 1000;
    padding: 1rem;

    &-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      padding: 0 0.5rem;
    }

    &-close {
      background: transparent;
      border: none;
      color: #666;
      font-size: 1.5rem;
      line-height: 1;
      cursor: pointer;
      padding: 0.25rem 0.5rem;
      border-radius: 4px;
      transition: all 0.2s ease;

      &:hover {
        background: #f0f0f0;
        color: #333;
      }
    }

    &-view-all {
      background: #6366F1;
      border: none;
      color: white;
      padding: 0.5rem 1rem;
      border-radius: 4px;
      font-size: 0.875rem;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        background: #4f46e5;
      }
    }
  }
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;

  &__content {
    background: transparent;
    max-width: 90%;
    max-height: 90vh;
    overflow-y: auto;
  }
}
