.ProductImages__detail {
  padding: 20px;
  width: 45vw;
  margin: 0 auto;
  text-align: center;
}

.ProductImages__header {
  position: relative;

}

.ProductImages__image {
  background-color: rgba(255, 255, 255, 0.598);
  max-width: 100%;
  height: 500px;
  object-fit: contain; 
}

.ProductImages__rating {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.ProductImages__star {
  color: gold;
}

.ProductImages__rating-value {
  font-size: 1.2em;
  margin-left: 5px;
}

.ProductImages__thumbnails {
  margin-top: 20px;
}

.ProductImages__thumbnail {
  // width: 100px;
  // height: 100px;
  // object-fit: cover;
  max-width: 100%;
  max-height: 100px;
  object-fit: contain; 

  margin: 0 5px;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  cursor: pointer;
}

.ProductImages__description {
  margin-top: 20px;
  font-size: 1em;
  color: #333;
  text-align: left;
  font-weight: 700;
}
