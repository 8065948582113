.LoginForm {
  &__input-group {
    margin-bottom: 15px;
    position: relative;
    
    .ErrorText {
      color: #d32f2f;
      font-size: 12px;
      margin-top: 4px;
      display: block;
      font-weight: 500;
    }
    
    input.error {
      border: 1px solid #d32f2f;
      background-color: rgba(211, 47, 47, 0.05);
    }
  }
  
  &__input-password {
    position: relative;
    
    .ErrorText {
      margin-top: 4px;
    }
  }
}

/* Enhanced error styling */
.error-container {
  padding: 8px 12px;
  background-color: rgba(211, 47, 47, 0.1);
  border-left: 3px solid #d32f2f;
  border-radius: 4px;
  margin-bottom: 15px;
}

/* Styling for general errors */
.general-error {
  text-align: center;
  background-color: rgba(211, 47, 47, 0.1);
  padding: 8px 12px;
  border-radius: 4px;
  margin-bottom: 15px;
  font-weight: 500;
}

/* Success message styling */
.success-message {
  color: #2e7d32;
  background-color: rgba(46, 125, 50, 0.1);
  padding: 8px 12px;
  border-radius: 4px;
  margin-bottom: 15px;
  text-align: center;
  font-weight: 500;
}