*, *::before, *::after {
  box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

body {
  font-family: 'DM Sans', sans-serif;
}

.ErrorText {
  text-align: start;
  font-size: 10px;
  color: red;
  position:relative;
}

.closeForm {
  position: absolute; /* Position relative to nearest positioned ancestor */
  top: 10px;         /* Adjust as needed for vertical position */
  right: 10px;        /* Adjust as needed for horizontal position */
  cursor: pointer;
  font-size: 20px;   /* Keep original size */
  color: #555;        /* Dark grey often works better than white */
  background: none;  /* Ensure no background interferes */
  border: none;      /* Ensure no border interferes */
  padding: 0;        /* Ensure no padding interferes */
  line-height: 1;    /* Helps with alignment */
}

.closeForm:hover {
  color:firebrick;
}

button {
  cursor: pointer;
}

tr > a {
  text-decoration: none;
}
a {
  text-decoration: none;
  color: black;
}

.error input {
  border: 2px solid red;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}
.App {
  margin-top: 100px; /* Default margin for expanded header */
}

/* Override margin specifically for the homepage */
.App.homepage {
  margin-top: 72px; 
}