.SubscribtionProfile {
  width: 75%;
  border: 2px solid #CCCCCC;
  margin: 30px;
  border-radius: 30px;
  padding: 30px;
  height: 100%;
 .SubscriptionType {
  max-width: 35%;
  font-size: 0.85em;
 }
 &__container {
  display: flex;
 }
}

.SubscribtionProfile__current {
  display: flex;
  margin: 0 10px;
  gap: 20px;
}

.SubscribtionProfile__current__column {
  flex: 1;
}

.SubscribtionProfile__current__label {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #333;
  margin: 5px 0;
}

.SubscribtionProfile__current__value {
  font-weight: bold;
  margin-left: 10px;
}
