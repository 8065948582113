.WareHousesDescription {
  margin-left: 40px;
  max-width: 65vw;
  &__title {
    font-size: 24px;
    font-weight: 500;
  }
  &__content {
    font-size: 18px;
    font-weight: 700;
  }
}