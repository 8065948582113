.TeamMembers {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  padding: 16px;
  margin: 40px;
  &__header {
    display: flex;
  }
  &__details {
    margin-top: 20px;
    margin-left: 20px;
  }
  &__img {
    width: 140px;
    height: 140px;
    border-radius: 70px;
  }
  &__full_name {
    font-size: 24px;
    font-weight: 500;
    color: #2D2D2A;
  }
  &__position {
    margin-top: 5px;
    color: #808080;
    font-size: 20px;
    font-weight: 400;
  }
  &__joined_at {
    margin-top: 5px;
    color: #808080;
    font-size: 15px;
    font-weight: 400;
  }
  &__description {
    padding: 20px;
    padding-left: 0;
    font-size: 18px;
    font-weight: 700;
    color: #2D2D2A;
  }
}
.TeamMembersEmpty {
  width: 60vw;
}