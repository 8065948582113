.Reseller {
  padding: 20px;
  margin: 20px 5vw;

  &__title {
    font-size: 2rem;
    margin-bottom: 20px;
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }

  &__type {
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-end;
    padding: 10px;
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
    height: 200px;
    justify-content: flex-start; 
    align-items: flex-start; 

    span {
      background-color: black;
      padding: 5px 10px;
      border-radius: 10px;
    }

    &--large {
      grid-column: span 2;
      height: 200px;
    }
  }

  .Hotels {
    background-image: url('./images/hotels.png');
  }
  .Bars {
    background-image: url('./images/bars.png');
  }
  .Webshop {
    background-image: url('./images/webshop.png');
  }
  .Restaurants {
    background-image: url('./images/restaurants.png');
  }
  .Supermarkets {
    background-image: url('./images/supermarkets.png');
  }
  .Cafes {
    background-image: url('./images/cafes.png');
  }
  .Alcohol_shops {
    background-image: url('./images/alcohol_shops.png');
  }
}
