.SideBar {
  width: 25vw;
  border: 1px solid #E7E7E7;
  min-height: 50vh;
  border-radius: 20px;
  margin-left: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  &__title {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  &__icon {
    width: 20px;
    margin: 0 20px;
  }
  &__categories {
    display: flex;
    margin-bottom: 10px;
  }
  &__button {
    padding: 10px;
    color: #0083FD;
    border: 2px solid #0083FD;
    background-color: white;
    border-radius: 10px;
    margin-top: 30px;
    &__black {
      color: black;
    border: 2px solid black;
    }
  }
  &__text {
    color: #808080;
    &:hover {
      cursor: pointer;
    }
    &__chousen {
      color: black;
    }
  }
}