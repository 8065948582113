.Features {
  padding: 20px;

  &__title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
    padding: 20px 80px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    list-style-type: none;
    padding: 0px;
    margin: 0;
    padding: 20px 60px;
  }

  &__list__item {
    background-color: #f0f0f0;
    border-radius: 20px;
    padding: 20px;
    flex: 1 1 calc(25% - 20px);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    &:hover {
      .Features__list__item__text {
        color: #333;
      }
    }
    &__title {
      background-color: #333;
      color: #fff;
      font-size: 0.8rem;
      font-weight: bold;
      padding: 10px 15px;
      border-radius: 20px;
      position: absolute;
      top: 0px;
      left: 20px;
    }

    &__text {
      margin-top: 40px;
      font-size: 1rem;
      color:  #7B7B7B80;
      line-height: 1.5;
    }
  }

  &__list__item--large {
    flex: 1 1 calc(50% - 20px);
  }
}
