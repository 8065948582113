.Pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.Pagination__button {
  padding: 10px 14px;
  border: none;
  border-radius: 4px;
  background-color: #f0f0f0;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s;
}

.Pagination__button:hover:not(:disabled) {
  background-color: #e0e0e0;
}

.Pagination__button:disabled {
  background-color: #d0d0d0;
  cursor: not-allowed;
}

.Pagination__button.active {
  background-color: #007bff;
  color: white;
  cursor: default;
}
