.PaymentChoice__form {
  margin: 20px;
  margin-left: 40px;
}

.PaymentChoice__option {
  border: 1px solid #e1e1e1;
  border-radius: 18px;
  padding: 20px 20px 0 20px;
  background-color: white; 
  font-weight: 500;
  margin-bottom: 20px;
}

.PaymentChoice__option.checked {
  background-color: #f9f9f9; /* Change background color when checked */
}

.PaymentChoice__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.PaymentChoice__checkbox {
  margin-right: 10px;
}

.PaymentChoice__card-logo {
  width: 40px;
  height: auto;
  margin-left: 15px;
}

.PaymentChoice__details {
  display: none; /* Hide details by default */
  flex-direction: column;
}

.PaymentChoice__option.checked .PaymentChoice__details {
  display: flex; /* Show details when the option is checked */
}

.PaymentChoice__input-group {
  margin-bottom: 15px;
}

.PaymentChoice__input-group label {
  display: block; /* Ensure label is displayed as a block element */
  margin-bottom: 5px; /* Add some space between label and input */
}

.PaymentChoice__input {
  width: 100%; /* Full width for card number */
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box;
}

.PaymentChoice__input--small {
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box;
}

.PaymentChoice__expiry-cvc {
  display: flex;
  gap: 15px;
}

.PaymentChoice__expiry-cvc > div {
  flex: 1; 
}

.PaymentChoice__ssl-info {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

.PaymentChoice__ssl-logo {
  width: 10px;
  height: auto;
  margin-right: 5px;
}

.BasketPaymentMethod-button {
  width: 100%;
  background-color: black;
  color: white;
  border-radius: 10px;
  padding: 10px;
}

.PaymentChoice__invoice-detail {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.PaymentChoice__invoice-detail div {
  flex: 1;
  padding: 10px;
}

.PaymentChoice__invoice-detail label {
  display: block;
  font-weight: bold;
}

.PaymentChoice__invoice-detail span {
  display: block;
  margin-top: 5px;
}

.PaymentChoice__invoice-detail a {
  color: #007bff;
  text-decoration: none;
}

.PaymentChoice__invoice-detail a:hover {
  text-decoration: underline;
}

.PaymentChoice__input-line {
  display: flex;
  gap: 15px;
}

.PaymentChoice__input-line .PaymentChoice__input-group {
  flex: 1;
  margin-bottom: 20px; /* Remove bottom margin for input groups in the same line */
}
