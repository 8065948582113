.category-selector {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;

  &__section {
    width: 100%;
    margin-bottom: 1.5rem;
  }

  &__label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
    color: #333;
  }

  &__select {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fff;
    font-size: 1rem;
    transition: border-color 0.2s ease;

    &:focus {
      outline: none;
      border-color: #0066cc;
      box-shadow: 0 0 0 2px rgba(0, 102, 204, 0.1);
    }

    &:disabled {
      background-color: #f5f5f5;
      cursor: not-allowed;
      opacity: 0.7;
    }

    &[multiple] {
      height: 150px;
      padding: 0.5rem;

      option {
        padding: 0.5rem;
        
        &:checked {
          background-color: #0066cc;
          color: white;
        }

        &:hover {
          background-color: #f0f0f0;
        }
      }
    }
  }

  &__title {
    font-size: 1rem;
    font-weight: 600;
    color: #2d3748;
    margin: 0;
  }

  &__help-text {
    font-size: 0.875rem;
    color: #718096;
    margin-top: 0.25rem;
  }

  &__error {
    color: #dc3545;
    font-size: 0.875rem;
    margin-top: 0.5rem;
    padding: 0.25rem 0;
  }

  &__loading {
    color: #666;
    font-size: 0.875rem;
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    
    &::before {
      content: '';
      display: inline-block;
      width: 1rem;
      height: 1rem;
      margin-right: 0.5rem;
      border: 2px solid #ddd;
      border-top-color: #0066cc;
      border-radius: 50%;
      animation: spin 1s linear infinite;
    }
  }

  &__breadcrumb {
    margin-top: 1rem;
    padding: 0.75rem;
    background-color: #f7fafc;
    border-radius: 6px;
    font-size: 0.875rem;
    color: #4a5568;

    span {
      &:not(:last-child)::after {
        content: " >";
        color: #a0aec0;
        margin: 0 0.5rem;
      }
    }
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

// Add Material-UI Autocomplete custom styles
.MuiAutocomplete-root {
  .MuiOutlinedInput-root {
    padding: 3px !important;
  }

  .MuiAutocomplete-input {
    padding: 7.5px 4px 7.5px 6px !important;
  }

  .MuiAutocomplete-endAdornment {
    right: 9px !important;
  }
}