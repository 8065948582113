.TeamList__button {
  height: 30px;
  width: 250px;
  margin-top: 20px;
}

.ProductForm__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ProductForm__title {
  font-size: 1.5rem;
  font-weight: bold;
}

.TeamList__button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 5px 5px;
  cursor: pointer;
  border-radius: 5px;
}

.TeamList {
  margin-top: 1rem;
}

.TeamList__member {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 5px;
}

.TeamList__member-photo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 1rem;
}

.TeamList__member-info {
  flex: 1;
}

.TeamList__member-name {
  font-size: 1.25rem;
  font-weight: bold;
}

.TeamList__member-position,
.TeamList__member-joined,
.TeamList__member-description {
  margin: 0.25rem 0;
}

.TeamList__actions {
  display: flex;
  align-items: center;
}

.TeamList__icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-bottom: 0.5rem;
}
