.Banner {
  background-color: #2D2D2A;
  min-height: calc(90vh - 100px); /* Adjusted to match .App margin-top for header */
  padding: 47px 80px 20px 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__title {
    font-family: 'DM Sans', sans-serif;
    font-size: clamp(32px, 5vw, 63px);
    color: white;
    line-height: 1.2;
    margin-bottom: 1rem;
  }

  &__p {
    color: white;
    font-family: 'DM Sans', sans-serif;
    font-size: clamp(18.2px, 2.6vw, 20.8px);
    max-width: 600px;
    line-height: 1.6;
    margin-bottom: 2rem;
  }

  &__buttons {
    display: flex;
    gap: 20px;
    margin-bottom: 2rem;
  }

  &__button {
    cursor: pointer;
    padding: clamp(8px, 2vw, 10px) clamp(16px, 3vw, 20px);
    border-radius: 30px;
    font-family: 'DM Sans', sans-serif;
    font-size: clamp(12px, 1.5vw, 14px);
    color: white;
    background-color: #0083FD;
    border: 0px;
    font-weight: 700;
    transition: transform 0.2s ease;

    &:hover {
      transform: translateY(-2px);
    }

    &__gray {
      background-color: #808080;
    }
  }

  @media (max-width: 768px) {
    text-align: center;
    padding: 20px;
    
    &__p {
      margin-left: auto;
      margin-right: auto;
    }

    &__button {
      margin: 10px auto;
    }
  }
}