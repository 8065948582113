.TopButtons__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.TopButtons__back__container {
  display: flex;
  align-items: center;
}

.TopButtons__back__button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  background-color: #f0f0f0;
  border: none;
  border-radius: 50px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  margin-right: 0.5rem;
}

.TopButtons__back__text {
  font-size: 1rem;
  color: #666;
}

.WareHouseButton__conteiner,
.MyWareHouseButton__conteiner {
  display: flex;
  gap: 1rem;
}

.WareHouseButton__lead,
.MyWareHouseButton__edit {
  padding: 0.5rem 1rem;
  border: 1px solid #007bff;
  border-radius: 8px;
  background-color: transparent;
  color: #007bff;
  cursor: pointer;
  font-size: 1rem;
}

.WareHouseButton__contact,
.MyWareHouseButton__add {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 8px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
}
.MyWareHouseButton__conteiner {
  margin-left: auto
}