.WareHousesFeed {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  &__location {
    font-size: 16px;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
  }
  &__star{
    width: 15px;
    margin-right: 5px;
  }
  &__reting {
    margin-right: 10px;
  }
}

.WareHousesFeed__container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  margin: auto 0;
}

.WareHousesFeed__wrapper {
  overflow: hidden;
  width: 90%;
  margin: auto;
}

.WareHousesFeed__slider {
  display: grid;
  transition: transform 0.5s ease-in-out;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.WareHousesFeed__item {
  min-width: calc(100% / 12); 
  display: flex;
  flex-direction: column;
  a {
    text-decoration: none;
    color: #2D2D2A;
    ;
  }
}

.WareHousesFeed__image {
  width: 100%;
  height: 230px;
  object-fit: contain;
}

.WareHousesFeed__name {
  margin-top: 10px;
  font-weight: 500;
  font-size: 20px;
}

.WareHousesFeed__button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  z-index: 1;
  &--right {
    margin-right: auto;
  }
  &--left {
    margin-left: auto;
  }
}

.WareHousesFeed__button:hover {
  background-color: #0056b3;
}
