.Gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 15px; 
  justify-content: space-between;
  margin: 10px 40px 0 20px;

  &__card {
    position: relative;
    width: calc(33.333% - 20px); 
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__img {
    width: 100%;
    height: 200px;
    object-fit: contain;
  }
}