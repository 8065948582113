.SignUpForm {
  position: relative;
  max-width: 480px;
  width: 100%;
  background: #FFFFFF;
  border-radius: 12px;
  padding: 32px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);

  &__title {
    font-size: 24px;
    font-weight: 600;
    color: #1A1A1A;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .closeForm {
      cursor: pointer;
      font-size: 20px;
      color: #666;
      &:hover {
        color: #333;
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__input-group {
    display: flex;
    flex-direction: column;
    gap: 8px;

    label {
      font-size: 14px;
      font-weight: 500;
      color: #1A1A1A;
    }

    input, select {
      height: 40px;
      padding: 8px 12px;
      border: 1px solid #E5E5E5;
      border-radius: 8px;
      font-size: 14px;
      transition: border-color 0.2s;

      &:focus {
        border-color: #007AFF;
        outline: none;
      }

      &::placeholder {
        color: #999;
      }
    }

    .select-wrapper {
      position: relative;
      
      &::after {
        content: '';
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        width: 10px;
        height: 10px;
        background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 5L9 1' stroke='%23666666' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        pointer-events: none;
      }
    }
  }

  &__input-group__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }

  &__input-group--half {
    width: 100%;
  }

  .Contry__select {
    .css-13cymwt-control {
      min-height: 40px;
      border-color: #E5E5E5;
      border-radius: 8px;
      
      &:hover {
        border-color: #007AFF;
      }
    }

    .css-t3ipsp-control {
      min-height: 40px;
      border-color: #007AFF;
      border-radius: 8px;
      box-shadow: none;

      &:hover {
        border-color: #007AFF;
      }
    }
  }

  .ErrorText {
    color: #FF3B30;
    font-size: 12px;
    margin-top: 4px;
  }

  &__privacy {
    display: flex;
    gap: 8px;
    align-items: flex-start;
    margin-top: 8px;

    input[type="checkbox"] {
      margin-top: 3px;
    }

    label {
      font-size: 12px;
      color: #666;
      
      a {
        color: #007AFF;
        text-decoration: none;
        
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__terms {
    font-size: 12px;
    color: #666;
    margin-top: 16px;
    text-align: center;

    a {
      color: #007AFF;
      text-decoration: none;
      margin: 0 4px;
      
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__submit {
    width: 100%;
    height: 48px;
    background: #007AFF;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
    margin-top: 24px;

    &:hover {
      background: #0066CC;
    }

    &:active {
      background: #004999;
    }
  }

  &__footer {
    margin-top: 24px;
    text-align: center;
    font-size: 14px;
    color: #666;

    a {
      color: #007AFF;
      text-decoration: none;
      font-weight: 500;
      
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

// Modal background overlay
.SignUpForm-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
