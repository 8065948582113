.ModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; 
  overflow: hidden;
}

.PaymentModal {
  width: 400px;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001; 
  
  &__close {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 18px;
    color: #e7f1ff;
  }

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    color: #e7f1ff;
    padding: 10px 5px;
    margin: -20px -20px 20px -20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;
  }

  &__img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 20px;
  }

  &__container {
    max-width: 600px;
  }
}

.PaymentModal__methods {
  margin-top: 20px;
  max-height: 200px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 400px;
}

.PaymentMethodCard__container {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  gap: 10px;
  flex-direction: row;
  margin-right: 10px;
}

.PaymentMethodCard.selected {
  border-color: #007bff;
  background-color: #e7f1ff;
}

.payment-form__card-wrapper {
  flex: 1;
}

.payment-form__card-element {
  background-color: white;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 340px;
}

.payment-form__button {
  margin-top: 20px;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 400px;
  font-size: 16px;
}

.payment-form__button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.payment-form__new-card-selection {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.payment-form__new-card-selection input {
  margin-right: 10px;
}
