// .image-upload-button {
//   display: inline-block;
//   width: 150px;
//   height: 150px;
//   background-color: #ddd;
//   text-align: center;
//   line-height: 150px;
//   font-size: 56px;
//   cursor: pointer;
//   margin-bottom: 10px;
//   border: 3px solid black;
//   border-radius: 30px;
// }

// .images-grid {
//   display: flex;
//   flex-wrap: wrap;
//   gap: 20px;
// }

// .image-container {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   width: 150px;
// }

// .product-image {
//   width: 150px;
//   height: 150px;
//   border: 2px solid black;
//   border-radius: 30px;
//   margin-bottom: 5px;
// }

// .image-buttons {
//   display: flex;
//   gap: 10px;
// }

// .image-buttons button {
//   padding: 5px 10px;
//   font-size: 12px;
//   cursor: pointer;
// }
// .product-image-main {
//   border: 2px solid green;
// }

// .main-button {
  

// }

// .delete-button {

// }

.image-upload-button {
  display: inline-block;
  width: 150px;
  height: 150px;
  background-color: #ddd;
  text-align: center;
  line-height: 150px;
  font-size: 56px;
  cursor: pointer;
  margin-bottom: 10px;
  border: 3px solid black;
  border-radius: 30px;
}

.images-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.image-container {
  position: relative;
  width: 150px;
  height: 150px;
  margin-bottom: 20px; /* Adjust this to provide space for buttons */
}

.product-image {
  width: 100%;
  height: 100%;
  border: 2px solid black;
  border-radius: 30px;
  object-fit: contain;
}

.image-buttons {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 5px;
}

.image-buttons img {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.product-image-main {
  border: 2px solid green;
}

.main-button-main {
  filter: invert(96%) sepia(96%) saturate(3500%) hue-rotate(360deg) brightness(105%) contrast(105%);
}