.Poster {
  background-image: url('./images/poster.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 600px;
  margin: 50px 7vw;
  border-radius: 30px;
  position: relative;

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5); 
    border-radius: 30px; 
  }

  &__title {
    color: white;
    font-size: 3rem;
    text-align: center;
    margin-bottom: 20px;
  }

  &__button {
    background-color: white;
    color: black;
    font-size: 20px;
    font-weight: 700;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #ddd;
    }
  }
}