.Cocktail {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; // Расстояние между карточками
  justify-content: space-between;

  &__card {
    background: white;
    padding: 20px;
    width: 270px;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__img {
    width: 270px;
    height: 200px;
    border-radius: 30px;
    border: 1px solid gray;
  }

  &__rating {
    position: absolute;
    top: 30px;
    right: 30px;
    background-color: rgba(255, 255, 255, 0.7);
    color: #000000;
    padding: 5px 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;

    img {
      margin-right: 5px;
      width: 14px;
      height: 14px;
    }
  }

  &__title {
    font-size: 1.25em;
  }

  &__button {
    background: white;
    border: 1px solid #000;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 14px;
    transition: background 0.3s;

    &:hover {
      background: #f0f0f0;
    }
  }
}