.filtersContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-bottom: 10px;
}

.checkItem {
  display: flex;
  align-items: center;
}

.checkItem label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px; 
  font-size: 14px;
}

.Filter {
  margin: 10px;
  display: flex;
  flex-direction: column;
}

.FiltersSubTitle {
  font-weight: 700;
  font-size: 18px;
}

.ShowMore {
  text-decoration: underline;
  padding-top: 30px;
  &:hover{
    cursor: pointer;
  }
}
.FilterBlock {
  border-bottom: 1px solid gray;
  padding-bottom: 10px;
}

.ClearAll{
  text-align: right;
  margin-bottom: 10px;
}