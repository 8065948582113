.Billing {
  width: 75%;
  border: 2px solid #CCCCCC;
  margin: 30px;
  border-radius: 30px;
  padding: 30px;
}

.Billing {


  &__payment_methods {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
}

.PaymentMethodCard {
  background-color:dodgerblue;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 260px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &__brand {
    width: 30px;
    height: 30px;
  }

  &__number {
    font-size: 16px;
    font-weight: 700;
    margin: 10px 0;
    align-self: flex-start;
    color: white;
  }


  &__expiry{
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: white;
    font-size: 13px;
    font-weight: 400;
  }
}



.Card {
  &__header{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 20px;
  }
  &__icon {
    width: 20px;
    height: 20px;
    filter: invert(1) brightness(2); 
  }
  &__icon_black {
    filter: invert(1) brightness(2); 
  }
  &__actions {
    display: flex;
    gap: 10px;
    }
  &__icon_wifi {
    width: 25px;

  }
  &__chip {
    align-self: flex-start;
    margin-top: 30px;
    width: 30px;
    margin-bottom: 10px;
  }


}
.yellow-star {
  filter: invert(62%) sepia(100%) saturate(1091%) hue-rotate(0deg) brightness(106%) contrast(104%);
}