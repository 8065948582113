.Benefits {
  padding: 40px 20px;

  @media (min-width: 768px) {
    padding: 60px 40px;
  }

  @media (min-width: 1024px) {
    padding: 80px;
  }

  &__title {
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-size: 40px;
    color: #7B7B7B;
    margin-bottom: 40px;
  }

  &__description {
    font-family: 'DM Sans', sans-serif;
    font-size: 20.8px;
    margin-bottom: 40px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    color: #7B7B7B;
  }

  &__box {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 20px;
  }

  &__content {
    display: flex;
    gap: 60px;
    align-items: flex-start;

    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }

  &__list {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 24px;
    list-style: none;
    padding: 0;
    margin: 0;
    max-width: 600px;

    &__element {
      background-color: #F2F1F1;
      border-radius: 24px;
      padding: 32px;
      transition: all 0.3s ease;
      cursor: pointer;

      &--active {
        background-color: #F9F9F9;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);

        .Benefits__list__element__title {
          color: #2D2D2A;
          opacity: 1;
        }

        .Benefits__list__element__text {
          color: #7B7B7B;
          opacity: 1;
        }
      }

      &:hover {
        background-color: #F9F9F9;
        transform: translateY(-2px);
      }

      &__title {
        font-family: 'DM Sans', sans-serif;
        font-size: 27px;
        font-weight: 700;
        margin-bottom: 16px;
        color: #2D2D2A;
        opacity: 0.5;
        transition: opacity 0.3s ease;
      }

      &__text {
        font-family: 'DM Sans', sans-serif;
        font-size: 19px;
        line-height: 1.2;
        color: #7B7B7B;
        margin: 0;
        opacity: 0.5;
        transition: opacity 0.3s ease;
      }
    }
  }

  &__image-container {
    flex: 1;
    position: sticky;
    top: 100px;
    height: calc(100vh - 200px);
    max-height: 800px;
    border-radius: 24px;
    overflow: hidden;

    @media (max-width: 1024px) {
      position: relative;
      top: 0;
      height: 400px;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 24px;
    transition: transform 0.6s ease;
  }

  @media (max-width: 768px) {
    padding: 40px 20px;

    &__title {
      font-size: 32px;
      margin-bottom: 30px;
      text-align: center;
    }

    &__description {
      font-size: 18px;
      margin-bottom: 30px;
    }

    &__box {
      padding: 0;
    }

    &__list {
      gap: 16px;

      &__element {
        padding: 24px;

        &__title {
          font-size: 22px;
        }

        &__text {
          font-size: 16px;
        }
      }
    }

    &__image-container {
      height: 300px;
    }
  }
}