.OrderHeader {
  display: flex;
  justify-content: space-between;
  margin-left: 40px;
  margin-right: 30vw;
  margin-top: 30px;
  p {
    color: #808080;
  }
}

.OrdersPage {
  display: flex;
  flex-direction: column;

  &__buttons {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    align-self: center;
    background-color: black;
    border-radius: 25px;

    .OrdersPage__button {
      padding: 10px 20px;
      border-radius: 25px;
      background-color: white;
      color: black;
      cursor: pointer;
      transition: background-color 0.3s, color 0.3s;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .OrdersPage__button--not-active {
      background-color: black;
      color: white;
    }
    .OrdersPage__button--active {
      border: 3px solid #EAEAEA;
    }

    .OrdersPage__separator {
      color: white;
      font-size: 20px;
    }
  }
}
