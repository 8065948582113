.WareHousesHeader {
  position: relative;
  width: 65vw;
  border-radius: 30px;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 10px;
  min-height: 350px; 
  background-size: contain;
  background-position: center; 
  background-repeat: no-repeat; 
  
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    object-fit: contain;
  }

  &__content {
    position: relative;
    z-index: 1; 
    display: flex;
    flex-direction: column;

    height: 100%;
    color: white; 
  }

  &__logo {
    max-width: 80px; 
    max-height: 80px;
    margin: 40px;
    border-radius: 40px;
  }

  &__name {
    font-size: 55px;
    font-weight: 500;
    margin-top: 10px;
    padding-left: 40px ;
  }
  &__location {
    margin-top: 10px;
    padding-left: 40px ;
    font-size: 22px;
    font-weight: 500;
  }
  &__icon_container {
    margin-left: 40px;
    display: flex;
    gap: 10px;
    margin-top: 10px;
    img {
      max-width: 30px;
    }
  }
}
