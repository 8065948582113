.ExpertForm {
  padding: 20px;
  border-radius: 15px;
  width: 340px;
  text-align: center;
  position: relative;
  background-color: white;

  &__title {
    background-color: #333;
    color: #fff;
    padding: 10px 0;
    border-radius: 15px 15px 0 0;
    margin: -20px -20px 20px -20px;
    font-size: 1.2rem;
    position: relative;
  }

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__input-group {
    margin-bottom: 15px;
    text-align: left;
    position: relative;

    label {
      display: block;
      margin-bottom: 5px;
      font-weight: bold;
      color: #333;
    }

    input {
      width: 100%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      box-sizing: border-box;
      background-color: white;
    }

    &.error input {
      border-color: #d32f2f;
      background-color: rgba(211, 47, 47, 0.05);
    }
  }

  &__submit {
    background-color: #333;
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;

    &:hover {
      background-color: #555;
    }

    &:disabled {
      background-color: #999;
      cursor: not-allowed;
    }
  }

  .error-message {
    color: #d32f2f;
    font-size: 12px;
    margin-top: 4px;
    display: block;
  }

  p {
    color: #333;
    margin: 20px 0;
  }
} 