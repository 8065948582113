.Footer {
  background-color: #FAFAFA;

  padding: 40px 20px;

  &__content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 100px;
    max-width: 1000px;
    margin: 0 auto;
    padding-bottom: 20px;

  }

  &__section {
    &--brand {
      grid-column: span 2;
    }
  }

  &__logo {
    font-size: 2rem;
    font-weight: bold;
  }

  &__logo-container {
    margin-bottom: 0px;
  }

  &__logo-img {
    height: 40px;
    margin-bottom: 10px;
    filter: brightness(0); /* This makes the logo black */
  }

  &__mission {
    font-size: 0.875rem;
    color: #666;
  }

  h3 {
    font-size: 1.25rem;
    margin-bottom: 10px;
  }

  ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 15px;

    li {
      margin-bottom: 5px;
      font-size: 0.875rem;
      color: #333;
      a {
        text-decoration: none;
        color: inherit;
      }
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1000px;
    margin: 20px auto 0;
    font-size: 0.875rem;
    color: #999;

    a {
      margin-left: 20px;
      color: black;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.SocialIcons {
  height: 1em;
  margin-right: 5px;
}