.coming-soon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 60vh; /* Adjust as needed */
  text-align: center;
  padding: 2rem;
  background-color: #f8f9fa; /* Light background */
  border-radius: 8px;
  margin: 2rem auto;
  max-width: 600px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  h1 {
    font-size: 2.5rem;
    color: #343a40; /* Dark grey */
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.1rem;
    color: #6c757d; /* Medium grey */
    margin-bottom: 0.5rem;
    line-height: 1.6;
  }

  a {
    margin-top: 1.5rem;
    color: #007bff; /* Standard link blue */
    text-decoration: none;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }
}
