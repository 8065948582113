
.billing-form {
  width: 100%;
  max-width: 65vw;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;

}

.billing-form__button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.billing-form__button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.billing-form__button:hover:not(:disabled) {
  background-color: #0056b3;
}

.billing-form__card-element {
  background-color: white;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
}

.billing-form__card-element--focus {
  border-color: #007bff;
}

.billing-form__card-element--invalid {
  border-color: #e3342f;
}

.billing-form__card-element--webkit-autofill {
  background-color: #fefde5 !important;
}
