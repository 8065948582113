.ProductSideBar {
  display: flex;
  flex-direction: column;
  width: 20vw;
  border-radius: 20px;
  border: 2px solid #E7E7E7;
  padding: 30px;
  margin: 20px;
  
  &__title {
    color: #808080;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  
  &__info {
    color: #2D2D2A;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  
  &__button {
    margin-top: auto;
    width: 100%;
    height: 40px;
    border: 2px solid black;
    border-radius: 10px;
    color: black;
    background-color: white;
    font-weight: 500;
    font-size: 16px;
  }
}
