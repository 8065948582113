.WareHousesCatalog {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 20px;
}
.WareHousesCatalog__text {
  color: #2D2D2A;
  font-size: 16px;
  font-weight: 500;
  margin: 5px;
}

.ProductCard {
  background: white;
  padding: 20px;
  width: 270px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.WareHousesCatalog__product_img {
  width: 270px;
  height: 200px;
  margin-bottom: 10px;
  border-radius: 30px;
  border: 1px solid gray;
  object-fit: contain;
}

.WareHousesCatalog__rating {
  position: absolute;
  top: 30px;
  right: 30px;
  background-color: rgba(255, 255, 255, 0.7);
  color: #000000;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.WareHousesCatalog__rating img {
  margin-right: 5px;
  width: 14px;
  height: 14px;
}

.WareHousesCatalog__price {
  font-weight: bold;
  font-size: 18px;
  margin-top: 10px;
  margin-left: 5px;
}

.ProductCard button {
  background: white;
  border: 1px solid #000;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  margin-top: 10px;
  font-size: 14px;
  transition: background 0.3s;
}

.ProductCard button:hover {
  background: #f0f0f0;
}

.ProductCard button:disabled {
  background: #e0e0e0;
  cursor: not-allowed;
}
