.BasketHeaderButtons {
  margin: 20px;
  display: flex;
  justify-content: space-between;

  &__left_container{
    display: flex;
    gap: 20px;
  }
  &__back {
    width: 100px;
    border-radius: 30px;
    background-color: #F1F1F1;
    height: 40px;
    border: none;
    color: black;
    font-weight: 500;
  }
  &__span {
    align-self: center;
    color: #A1A1A1;
    font-size: 14px;

  }
  &__cotinue {
    background-color: white;
    border: 2px solid black;
    color: black;
    border-radius: 10px;
    padding: 10px 40px;
    font-weight: 700;
  }
}