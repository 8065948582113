.BasketCards {
  padding: 20px;
  width: 72vw;
  &__deleteImg {
    width: 20px;
    margin-left: 150px;
  }

  &__companySection {
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 10px;
  }

  &__companyHeader {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  &__productsList {
    border-top: 1px solid #ddd;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__productItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  &__productInfo {
    display: flex;
    align-items: center;
    flex: 2;
  }

  &__productImage {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 10px;
  }

  &__productDetails {
    display: flex;
    flex-direction: column;
  }

  &__productAmount {
    display: flex;
    align-items: center;
    flex: 1;

    button {
      width: 30px;
      height: 30px;
      border: none;
      color: gray;
      background-color: white;
      cursor: pointer;
    }
  }

  &__productPrice {
    flex: 1;
    font-size: 16px;
    font-weight: bold;
    text-align: right;
  }

  &__deleteButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 18px;
  }

  &__companyTotal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
    background-color: #F9F9F9;
    margin: -10px;
    padding: 10px;
  }
}
