.ProfileSideBar {
  width: 300px;
  border: 2px solid #CCCCCC;
  border-radius: 30px;
  margin: 30px;
  &__header{
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 2px solid #CCCCCC;
    padding: 10px;
  }
  &__icon {
    width: 20px;
    height: 20px;
    margin: 0 10px;
    filter: grayscale(100%) brightness(0%);
  }
  &__img {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    margin-bottom: 20px;
    object-fit: cover;
    object-position: center;
  }
  &__reset_photo {
    display: flex;
    margin-bottom: 10px;
    cursor: pointer;
  }
  &__title {
    font-weight: 500;
    font-size: 20px;
  }
  &__button {
    display: flex;
    gap: 10px;
    cursor: pointer;
  }
  &__chousen {
    color: gray;
    img {
      filter: invert(1)
    }
  }
  &__chousen_2 {
    color: gray;
    img {
      filter: invert(0.5)
    }
  }
  &__replace {
    color: #000000;
    font-weight: 400;
    font-size: 16px
  }
  &__list {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 10px;
  }
}
.ProfileSideBar__chousen--secend {
  color: gray;
    img {
      filter: invert(0.6)
    }
}