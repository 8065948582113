.OrdersForMe {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    padding: 20px 0;

    &:last-child {
      border-bottom: none;
    }

    &-image {
      width: 60px;
      height: 60px;
      object-fit: cover;
      border-radius: 5px;
      margin-right: 20px;
    }

    &-details {
      flex: 2;
      margin-right: 20px;
      h3 {
        margin: 0;
        font-size: 16px;
        color: #333;
      }
      p {
        margin: 5px 0 0;
        font-size: 14px;
        color: #666;
      }
    }

    &-status {
      flex: 1;
      display: flex;
      flex-direction: column;
      label {
        font-size: 14px;
        color: #666;
      }
      select {
        margin-top: 5px;
        padding: 5px;
        font-size: 14px;
        border-radius: 5px;
        border: 1px solid #ccc;
      }
    }

    &-delivery {
      margin-left: 40px;
      h4 {
        margin-top: 0;
        font-size: 16px;
        color: #333;
      }
      p {
        margin: 5px 0;
        font-size: 14px;
        color: #666;
      }
    }
  }

  &__total {
    text-align: right;
    margin-top: 20px;
    h3 {
      margin: 0;
      font-size: 18px;
      color: #333;
    }
  }
}
