.search-bar {
  width: 16rem;
  margin: 0 auto;
  position: relative;
  transition: all 0.3s ease;

  &.expanded {
    width: 100%;
    max-width: 800px;
    transform: scale(1.02);

    .search-bar__input-group {
      height: 62px;
      padding: 16px 24px;
    }

    .search-bar__section {
      opacity: 1;
      visibility: visible;
    }

    .search-bar__label {
      opacity: 1;
      visibility: visible;
    }

    .search-bar__divider {
      opacity: 1;
      visibility: visible;
    }

    .search-bar__button {
      padding: 4px 16px;

      &-icon {
        opacity: 0;
        visibility: hidden;
        width: 0;
      }

      span {
        opacity: 1;
        visibility: visible;
        width: auto;
      }
    }

    .search-bar__input {
      padding: 0 80px 0 32px;
    }
  }

  &__input-group {
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #DCDCDC;
    border-radius: 31px;
    padding: 4px 8px;
    height: 2rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;

    &:hover {
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    &:focus-within {
      border-color: #2D2D2A;
      box-shadow: 0 4px 6px rgba(45, 45, 42, 0.1);
    }
  }

  &__section {
    display: flex;
    flex-direction: column;
    gap: 4px;
    min-width: 120px;
    flex: 1;
    opacity: 1;
    visibility: visible;
    transition: all 0.3s ease;

    &:not(:nth-child(2)) {
      opacity: 0;
      visibility: hidden;
    }
  }

  &__label {
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-size: 13px;
    color: #2D2D2A;
    transition: all 0.3s ease;
    opacity: 0;
    visibility: hidden;
  }

  &__input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;
  }

  &__input-icon {
    position: absolute;
    left: 8px;
    color: #9E9E9E;
    pointer-events: none;
    stroke-width: 2;
  }

  &__input {
    border: none;
    outline: none;
    font-family: 'DM Sans', sans-serif;
    font-size: 13px;
    color: #2D2D2A;
    width: 100%;
    padding: 0 40px 0 32px;
    background: transparent;
    height: 100%;

    &::placeholder {
      color: #9E9E9E;
      opacity: 1;
      transition: opacity 0.3s ease;
    }
  }

  &__select {
    border: none;
    outline: none;
    background: transparent;
    font-family: 'DM Sans', sans-serif;
    font-size: 13px;
    color: #9E9E9E;
    padding: 0;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%239E9E9E' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 16px;
    padding-right: 24px;

    &:hover {
      color: #2D2D2A;
    }

    option {
      background: #FFFFFF;
      color: #2D2D2A;
      padding: 8px;
    }
  }

  &__divider {
    width: 1px;
    height: 24px;
    background-color: #DCDCDC;
    margin: 0 24px;
    align-self: center;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
  }

  &__button {
    position: absolute;
    right: 4px;
    display: flex;
    align-items: center;
    gap: 8px;
    background: #2D2D2A;
    border: none;
    border-radius: 23px;
    padding: 4px;
    color: #FFFFFF;
    cursor: pointer;
    transition: all 0.3s ease;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-size: 14px;
    white-space: nowrap;
    min-width: 24px;
    height: 24px;

    &:hover {
      background: #000000;
    }

    &-icon {
      stroke-width: 2;
      width: 16px;
      height: 16px;
      opacity: 1;
      visibility: visible;
      transition: all 0.3s ease;
    }

    span {
      opacity: 0;
      visibility: hidden;
      width: 0;
      transition: all 0.3s ease;
    }
  }
} 