.Subscription {
  padding: 60px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f9fa;

  &__title {
    font-size: 2.5rem;
    color: #2D2D2A;
    margin-bottom: 20px;
    text-align: center;
  }

  &__text {
    max-width: 800px;
    text-align: center;
    margin-bottom: 40px;
    color: #666;
    line-height: 1.6;
  }

  &__card {
    background: #f1f1f1;
    border-radius: 20px;
    padding: 20px 25px 30px;
    width: 23.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    margin-bottom: 30px;
    position: relative;
  }

  &__title-pill {
    position: relative;
    top: -10px;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    
    h2 {
      background-color: #333;
      color: white;
      padding: 8px 25px;
      border-radius: 30px;
      font-size: 1.2rem;
      font-weight: 600;
      display: inline-block;
      margin: 0;
    }
  }

  &__price {
    text-align: center;
    margin-bottom: 35px;
  }

  &__amount {
    font-size: 3rem;
    font-weight: bold;
    color: #222;
  }

  &__period {
    font-size: 1.2rem;
    color: #666;
  }

  &__feature-group {
    margin-bottom: 25px;
    padding: 0;
  }

  &__feature-title {
    font-size: 1.2rem;
    color: #222;
    margin-bottom: 15px;
    font-weight: 600;
  }

  &__feature-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__feature-item {
    padding: 0;
    color: #666;
    font-size: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__feature-text {
    display: flex;
    align-items: center;
    color: #555;
    position: relative;
    padding-left: 15px;
    
    &::before {
      content: "•";
      position: absolute;
      left: 0;
      color: #666;
    }
  }

  &__feature-value {
    color: #555;
    font-weight: 500;
    margin-left: auto;
    text-align: right;
  }

  &__status {
    text-align: center;
    font-weight: 600;
    color: #0083FD;
    margin-bottom: 20px;
    padding: 8px 0;
    border-radius: 8px;
    background-color: rgba(0, 131, 253, 0.1);
  }

  &__button {
    background-color: white;
    color: #333;
    border: none;
    padding: 15px 40px;
    border-radius: 30px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: transform 0.2s ease, background-color 0.2s ease;
    display: block;
    margin: 20px auto 0;
    width: 100%;
    max-width: 200px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &:hover {
      transform: translateY(-2px);
      background-color: #f0f0f0;
    }
  }

  &__modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  &__modal-content {
    position: relative;
    width: 90%;
    max-width: 600px;
    max-height: 90vh;
    overflow-y: auto;
  }

  &__modal-close {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #666;
    padding: 5px 10px;
    border-radius: 50%;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: #f0f0f0;
    }
  }

  @media (max-width: 768px) {
    padding: 40px 15px;

    &__title {
      font-size: 2rem;
    }

    &__text {
      font-size: 0.9rem;
      padding: 0 15px;
    }

    &__card {
      padding: 30px 15px;
      width: 100%;
      max-width: 23.5rem;
    }

    &__amount {
      font-size: 2.8rem;
    }

    &__feature-title {
      font-size: 1.2rem;
    }

    &__feature-item {
      font-size: 1rem;
      padding: 10px;
    }

    &__button {
      padding: 12px 30px;
      font-size: 1rem;
    }
  }

  @media (max-width: 480px) {
    &__card {
      padding: 20px 10px;
    }

    &__amount {
      font-size: 2.5rem;
    }

    &__feature-group {
      padding: 0 5px;
    }

    &__feature-item {
      font-size: 0.9rem;
    }
  }
}