.StarRating {
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #e0e0e0; 
  gap: 5px;
  margin-left: 20px;

  &__filled {
    color: gold; 
  }

  &__empty {
    color: #e0e0e0;
  }

  &__text {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 500;
  }
}
