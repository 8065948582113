.MyOrders {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  &__item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding: 10px 0;

    &:last-child {
      border-bottom: none;
    }

    &-image {
      width: 50px;
      height: 50px;
      object-fit: cover;
      border-radius: 5px;
      margin-right: 20px;
    }

    &-details {
      flex: 2;
      h3 {
        margin: 0;
        font-size: 16px;
        color: #333;
      }
      p {
        margin: 5px 0 0;
        font-size: 14px;
        color: #999;
      }
    }

    &-status {
      flex: 1;
      p {
        margin: 0;
        font-size: 14px;
        color: #999;
      }
    }

    &-actions {
      flex: 0.5;
      display: flex;
      justify-content: flex-end;
      .material-icons {
        cursor: pointer;
        color: #999;
      }
    }
  }
}
