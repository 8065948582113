.WareHousesType__bar {
  display: flex;
  align-items: center;
}

.WareHousesTypes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 85%;
  margin-left: 30px;
}

.WareHousesTypes__container {
  display: flex;
  margin-bottom: 20px;
  padding: 7px;
  &--selected {
    border: 2px solid black;
    border-radius: 20px;
  }
}

.WareHousesTypes__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.WareHousesTypes__icons {
  width: 50px;
  margin-top: 10px;
}

.WareHousesTypes__title {
  text-align: center;
  margin-top: 10px;
  font-weight: 700;
  font-size: 15px;
}

.WareHousesTypes__buttons {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px;
}

.nextImage {
  width: 25px;
  height: 25px;
  margin-top: 30px;
}
.prerImage {
  width: 25px;
  height: 25px;
  margin-top: 30px;
  transform: scaleX(-1);
  margin-right: 5px;
}

.nextImage:hover, .prerImage:hover {
  cursor: pointer;
}

.Filter__button {
  display: flex;
  align-items: center;
  border: 1px solid black;
  border-radius: 8px;
  padding: 8px 15px;
  background-color: white;
  cursor: pointer;
  font-weight: 700;
  margin-bottom: 5px;
  margin-left: 50px;

}

.Filter__img {
  height: 20px; 
  margin-right: 10px; 

}