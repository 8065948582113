.ShippingAddress {
  padding: 20px;
  width: 70vw;
  margin: 20px;

  h2 {
    margin-bottom: 20px;
  }
  &__add {
    margin-bottom: 20px;
  }

  &__address {
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 15px;
    margin-bottom: 10px;
    background-color: #f9f9f9;
  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__2 {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-left: 10px;
  }

  &__3 {
    display: flex;
  }

  &__formRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding-right: 30px;

    > div {
      flex: 1;
      margin-right: 10px;
      margin-left: 10px;

      &:last-child {
        margin-right: 0;
        input {
          width: 100%;
        }
      }

      label {
        display: block;
        margin-bottom: 5px;
        font-weight: 500;
        font-size: 16px;
      }

      input {
        width: 90%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 10px;
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;

    button {
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      margin: 20px;

      &:first-child {
        background-color: #000;
        color: #fff;
      }

      &:last-child {
        background-color: #f5f5f5;
        color: #000;
      }
    }
  }
}
