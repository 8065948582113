.ProductsList {
  width: 75%;
  border: 2px solid #CCCCCC;
  margin: 30px;
  border-radius: 30px;

  &__table {
    width: 100%;
    margin: 20px;
    padding-right: 20px;
  }

  &__top {
    display: flex;
    justify-content: space-between;
  }

  &__category_container {
    display: flex;
    gap: 20px;
  }

  &__header {
    border-bottom: 2px solid #CCCCCC;
  }

  &__add-button {
    background-color: black;
    color: white;
    height: 40px;
    width: 110px;
    margin: 20px;
    border-radius: 30px;
    font-weight: 500;
    font-size: 16px;
  }

  &__title {
    margin-left: 30px;
    margin-top: 30px;
    font-size: 26px;
    font-weight: 500;
  }

  &__category_title {
    margin-left: 30px;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px;
  }

  &__category_select {
    margin-top: 5px;
    height: 40px;
    width: 90px;
    border-radius: 40px;
    padding-left: 15px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    overflow: hidden; 
    background: white url('data:image/svg+xml;base64,...') no-repeat right 10px center;
    background-size: 12px;
  }

  &__icon {
    width: 20px;
    height: 20px;
    margin: 5px;
    cursor: pointer;
  }

  &__category_select::-ms-expand {
    display: none; 
  }

  &__product-image {
    width: 80px;
    height: 80px;
    object-fit: contain;
  }

  &__product {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__row_item {
    padding-left: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  &__table-header {
    padding-left: 20px;
    text-align: left;
    font-size: 20px;
    font-weight: 500;
  }
}
tbody {
  font-size: 18px;
  font-weight: 400;
}