.ActionHeaderButtons{
  &__container  {
    display: flex;
    justify-content: space-between;
    margin: 20px;
  }
  &__back {
    width: 100px;
    border-radius: 30px;
    background-color: #F1F1F1;
    height: 40px;
    border: none;
    color: black;
    font-weight: 500;
  }
  &__buttons {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  &__first_button {
    width: 200px;
    height: 40px;
    border: 2px solid black;
    border-radius: 10px;
    color: black;
    background-color: white;
    font-weight: 500;
    font-size: 16px;
  }
  &__second_button {
    width: 200px;
    height: 40px;
    border: 2px solid black;
    border-radius: 10px;
    color: white;
    background-color: black;
    font-weight: 500;
    font-size: 16px;
  }
}