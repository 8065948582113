.BestResellers {
  padding: 20px;
  margin-left: 20px;
  &__name {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 10px;
  }
  &__description {
    flex: 1;
    font-size: 14px;
    color: #666666;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 10px;
  }

  &__title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }

  &__item {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 30%;
    display: flex;
    flex-direction: column;
    text-align: left;

    & > img {
      width: 100%;
      height: 300px;
      object-fit: contain;
    }

    &__details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 10px;
    }
  }

  .WareHousesFeed__button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #666666;

    &:disabled {
      color: #cccccc;
      cursor: not-allowed;
    }

    &--right {
      margin-left: auto;
    }
  }
}
.BestResellers__details {
  display: flex;
  justify-content: space-between;
}
.BestResellers__icon {
  position: relative;
  top: -10px;
  left: -20px;
  img {
    width: 30px;
  }
}