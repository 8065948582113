.ProductPrice {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 20vw;
  gap: 20px;

  &__name {
    font-size: 26px;
    font-weight: 500;
  }

  &__SKU {
    font-size: 20px;
    font-weight: 500;
  }

  &__price {
    font-size: 25px;
    font-weight: 700;
  }

  &__button {
    width: 100%;
    height: 30px;
    border: 2px solid black;
    border-radius: 10px;
    color: white;
    background-color: black;
    font-weight: 500;
    font-size: 16px;
  }

  &__input {
    padding: 10px;
    width: 30%;
  }

  &__img {
    max-width: 100%;
    height: 50px;
    object-fit: contain; 
    margin-right: 10px;
    border-radius: 20px;
  }

  &__product-list {
    margin-top: 20px;
    
    & h3 {
      font-size: 22px;
      font-weight: 600;
    }
    & ol {
      list-style-type: none;
      padding: 0;
      & li {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        font-weight: 700;
        & img {
          margin-right: 20px;
        }
      }
    }
  }
}
