.BasketSummary {
  margin-top: 50px; 
  margin-right: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  width: 300px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  &__button {
    width: 100%;
    color: white;
    background-color: black;
    border-radius: 10px;
    padding: 10px;
    margin-top: 20px;
  }
  h2 {
    margin-bottom: 20px;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    h3 {
      font-size: 16px;
      font-weight: normal;
    }

    p {
      font-size: 16px;
      font-weight: bold;
    }
  }

  &__shipping {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    label {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      font-size: 16px;

      input {
        margin-right: 10px;
      }

      span {
        font-weight: bold;
      }
    }
  }
}
