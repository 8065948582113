.PasswordForm {
  display: flex;
  flex-direction: column;
  width: 75%;
  border: 2px solid #CCCCCC;
  margin: 30px;
  border-radius: 30px;
  padding: 30px;
  height: 350px;
}

.PasswordForm__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.PasswordForm__header h2 {
  margin: 0;
  margin-bottom: 20px;
  font-size: 26px;
  font-weight: 700;
}

.PasswordForm__buttons {
  display: flex;
  gap: 10px;
}

.PasswordForm__button--cancel,
.PasswordForm__button--save {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.PasswordForm__button--cancel {
  background-color: #f0f0f0;
  color: #000;
}

.PasswordForm__button--save {
  background-color: #000;
  color: #fff;
}

.PasswordForm__input-group {
  margin-bottom: 20px;
}

.PasswordForm__input-group label {
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 500;
}

.PasswordForm__input-password {
  display: flex;
  align-items: center;
}

.PasswordForm__input-password input {
  width: 95%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 1em;
}

.PasswordForm__toggle-password {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: -40px;
  padding: 0;
}

.PasswordForm__toggle-password img {
  width: 20px;
  height: 20px;
}
