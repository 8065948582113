.EmptyPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  background-color: #f9f9f9; 
}

.EmptyPage__img {
  max-width: 50%;
  height: auto;
  margin-bottom: 20px; 
  margin-top: 20px;
}

.EmptyPage__text {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 30px; 
  font-weight: 700;
}