// SearchResults.scss
// Main container
.search-results {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #eaeaea;
  }

  &__title {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
    color: #333;
  }

  &__count {
    color: #666;
    font-size: 0.9rem;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 1.5rem;
    margin-bottom: 2rem;
  }
}

// Product Card
.product-card {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  background-color: #fff;
  height: 100%;
  cursor: pointer;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }

  &__image-container {
    height: 180px;
    overflow: hidden;
    position: relative;
    background-color: #f5f5f5;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #999;
    font-size: 0.9rem;
  }

  &__content {
    padding: 1rem;
    flex-grow: 1;
  }

  &__title {
    margin: 0 0 0.5rem;
    font-size: 1.1rem;
    font-weight: 600;
    color: #333;
  }

  &__category {
    color: #666;
    font-size: 0.85rem;
    margin: 0 0 0.25rem;
  }

  &__company {
    color: #777;
    font-size: 0.85rem;
    margin: 0 0 0.75rem;
  }

  &__details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
    margin-bottom: 0.75rem;
  }

  &__detail {
    display: flex;
    flex-direction: column;
  }

  &__label {
    font-size: 0.75rem;
    color: #888;
  }

  &__value {
    font-size: 0.9rem;
    color: #333;
    font-weight: 500;
  }

  &__description {
    font-size: 0.85rem;
    color: #666;
    margin: 0.5rem 0 0;
    line-height: 1.4;
  }

  &__cta {
    padding: 0.75rem 1rem;
    background-color: #f9f9f9;
    border-top: 1px solid #eee;
  }

  &__button {
    width: 100%;
    padding: 0.5rem;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background-color: #2980b9;
    }
  }
}

// Buyer Card
.buyer-card {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  background-color: #fff;
  height: 100%;
  cursor: pointer;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }

  &__header {
    padding: 1rem;
    background-color: #f5f7fa;
    border-bottom: 1px solid #eaeaea;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    margin: 0;
    font-size: 1.1rem;
    font-weight: 600;
    color: #333;
  }

  &__location {
    font-size: 0.85rem;
    color: #666;
    display: inline-block;
    padding: 0.2rem 0.5rem;
    background-color: #e9f0f8;
    border-radius: 4px;
  }

  &__content {
    padding: 1rem;
    flex-grow: 1;
  }

  &__contact {
    margin-bottom: 1rem;
  }

  &__contact-name {
    margin: 0 0 0.25rem;
    font-weight: 500;
    color: #333;
  }

  &__contact-info {
    margin: 0 0 0.25rem;
    font-size: 0.85rem;
    color: #666;
  }

  &__details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
  }

  &__detail {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;
  }

  &__label {
    font-size: 0.75rem;
    color: #888;
  }

  &__value {
    font-size: 0.9rem;
    color: #333;
  }

  &__cta {
    padding: 0.75rem 1rem;
    background-color: #f9f9f9;
    border-top: 1px solid #eee;
  }

  &__button {
    width: 100%;
    padding: 0.5rem;
    background-color: #27ae60;
    color: white;
    border: none;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background-color: #219653;
    }
  }
}

// Auth Prompt
.auth-prompt {
  margin-top: 1.5rem;
  padding: 1.5rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  border: 1px solid #e1e4e8;

  &__content {
    text-align: center;
    max-width: 500px;
    margin: 0 auto;
  }

  h3 {
    color: #2c3e50;
    margin-top: 0;
    margin-bottom: 0.75rem;
    font-size: 1.25rem;
  }

  p {
    color: #666;
    margin-bottom: 1.5rem;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }

  &__button {
    padding: 0.5rem 1.25rem;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s, transform 0.1s;

    &:hover {
      transform: translateY(-2px);
    }

    &--login {
      background-color: transparent;
      border: 1px solid #3498db;
      color: #3498db;

      &:hover {
        background-color: rgba(52, 152, 219, 0.1);
      }
    }

    &--register {
      background-color: #3498db;
      border: 1px solid #3498db;
      color: white;

      &:hover {
        background-color: #2980b9;
      }
    }
  }
}

// Empty State
.empty-state {
  text-align: center;
  padding: 3rem 1rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  margin: 2rem 0;

  &__icon {
    font-size: 3rem;
    margin-bottom: 1rem;
    opacity: 0.7;
  }

  &__title {
    color: #333;
    margin: 0 0 0.75rem;
  }

  &__message {
    color: #666;
    max-width: 400px;
    margin: 0 auto;
    line-height: 1.5;
  }
}

// Loading State
.loading-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 1rem;
  
  &__spinner {
    width: 40px;
    height: 40px;
    border: 3px solid rgba(52, 152, 219, 0.3);
    border-radius: 50%;
    border-top-color: #3498db;
    animation: spin 1s ease-in-out infinite;
    margin-bottom: 1rem;
  }

  &__message {
    color: #666;
  }

  @keyframes spin {
    to { transform: rotate(360deg); }
  }
}

// Error State
.error-state {
  text-align: center;
  padding: 2rem 1rem;
  background-color: #fff5f5;
  border-radius: 8px;
  border: 1px solid #ffd6d6;
  margin: 2rem 0;
  
  &__icon {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
    color: #e74c3c;
  }

  &__title {
    color: #c0392b;
    margin: 0 0 0.75rem;
  }

  &__message {
    color: #666;
    max-width: 400px;
    margin: 0 auto;
  }
}

// Media queries for responsive design
@media (max-width: 768px) {
  .search-results {
    &__grid {
      grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    }
  }

  .auth-prompt {
    &__buttons {
      flex-direction: column;
      gap: 0.5rem;
    }
  }
}

@media (max-width: 480px) {
  .search-results {
    &__header {
      flex-direction: column;
      align-items: flex-start;
    }

    &__count {
      margin-top: 0.5rem;
    }

    &__grid {
      grid-template-columns: 1fr;
    }
  }
}
