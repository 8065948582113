.location-page {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.location-list {
  width: 40%;
  padding: 10px;
  box-sizing: border-box;
  overflow-y: auto;
}

.location-list__item {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 10px;
  border-bottom: 1px solid gray;
  a {
    text-decoration: none;
    color: black;
  }
}

.location-list__image {
  width: 100%;
  height: 250px;
  border-radius: 8px;
  object-fit: contain;
}

.location-list__name {
  font-size: 1.2em;
  font-weight: bold;
}

.location-list__rating {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.location-list__star {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.location-list__description {
  margin-top: 8px;
  color: #666;
  margin-bottom: 10px;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.location-list__button {
  background-color: transparent;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}

.location-list__button--left {
  position: absolute;
  left: 10px;
}

.location-list__button--right {
  position: absolute;
  right: 10px;
}

.map {
  width: 70%;
  height: 100%;
  position: relative;
}

.location-list__items_2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.location-list__title-container {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}
.location-list--title {
  margin-left: 20px;
  font-weight: 500;
  font-size: 34px;
}