.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh; // Adjust height as needed
  text-align: center;
  padding: 20px;

  h1 {
    font-size: 6rem; // Larger font size for 404
    color: #dc3545; // A red color, adjust as needed
    margin-bottom: 10px;
  }

  h2 {
    font-size: 2rem;
    margin-bottom: 15px;
  }

  p {
    font-size: 1.1rem;
    color: #6c757d; // A gray color
    margin-bottom: 25px;
  }

  .back-home-link {
    padding: 10px 20px;
    background-color: #007bff; // Primary button color, adjust
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #0056b3; // Darker shade on hover
    }
  }
}
