.CocatilDetail {
  margin: 10px;
  &__img {
    width: 65vw;
    border: 2px solid gray;
    border-radius: 30px;
    margin-left: 20px;
  }
  &__title {
    font-weight: 700;
    font-size: 32px;
    margin-left: 20px;
  }
  &__title_2 {
    font-weight: 500;
    font-size: 32px;
    margin-left: 20px;
  }
  &__text {
    font-weight: 700;
    font-size: 18px;
    margin-left: 20px;
  }

}

.CocktailDetail__product-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.CocktailDetail__product-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 0;
  margin-bottom: 15px;
  margin-left: 25px;
}

.CocktailDetail__product-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
}

.CocktailDetail__product-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 15px;
  border-radius: 8px;
}

.CocktailDetail__product-name {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin-right: 10px;
}

.CocktailDetail__product-details {
  margin-left: auto;
  display: flex;
  flex-direction: column;
}

.CocktailDetail__product-category,
.CocktailDetail__product-price {
  font-size: 0.9rem;
  color: #555;
  margin: 2px 0;
}

.CocktailDetail__no-products {
  font-style: italic;
  color: #777;
}
