.CompanyForm {
  width: 75%;
  border: 2px solid #CCCCCC;
  margin: 30px;
  border-radius: 30px;
  padding: 30px;
  &__select-wrapper {
    width: 95%;
  }

  &__header {
    display: flex;
    justify-content: space-between;

    &__buttons {
      display: flex;
      gap: 10px;
      height: 35px;
    }

    &__button {
      padding: 5px 15px;
      border-radius: 10px;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;

      &--cancel {
        background-color: white;
        color: black;
        border: 2px solid black;
      }

      &--save {
        background-color: #0083FD;
        color: white;
        border: 1px solid #0083FD;
        width: 90px;
      }
    }
  }

  &__title {
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  &__section {
    display: flex;
    flex-direction: column;


  }
  &__row {
    display: flex;
    flex-wrap: wrap;
  }
  &__field {
    flex: 1 1 calc(30% - 20px); 
    display: flex;
    gap: 10px;
    flex-direction: column;
  }

  &__field_title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  &__input {
    width: 90%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    font-size: 1em;
  }
}
