.SubscriptionType {
  position: relative;
  background-color: #f0f0f0;
  border-radius: 30px;
  padding: 20px;
  margin: 10px;
  flex: 1 1 calc(29% - 20px); 
  box-sizing: border-box;
  max-width: calc(29% - 40px);
  text-align: left;
  margin-top: 30px;
  padding-bottom: 40px;
  min-height: 750px;
  &__choosen {
    background-color: #DCEEFF;
    border: 1px solid #0083FD;
  }
  &__price {
    text-align: center;
    margin: 0;
    &__main {
      font-size: 40px;
      font-weight: 700;
      &__sacond {
        font-size: 18px;
        font-weight: 400;
      }
    }
  }
  &__title-wrapper {
    text-align: center; 
  }

  &__title {
    color: white;
    background-color: black;
    display: inline-block;
    padding: 5px 10px; 
    font-weight: 700;
    font-size: 20px;
    border-radius: 20px;
  }
  &__p {
    text-align: center;
    color: #808080;
  }
  &__rates {
    margin-bottom: 5px;
    list-style-type: disc;
    margin-left: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 50px;
    color: #808080;
    position: relative;
    &--first::after {
      content: '';
      position: absolute;
      left: 15px;
      bottom: -5px; 
      width: 90%;
      height: 1px;
      background-color: #ccc; 
    }
    
  }

  &__button {
    position: absolute; 
    bottom: 30px; 
    left: 50%; 
    transform: translateX(-50%); 
    padding: 10px 20px;
    color: white;
    background-color: black;
    border-radius: 20px;
    border: 0;
    font-weight: 700;
    cursor: pointer;
    &--selected {
      background-color: #0083FD;
      cursor:default;
    }
  }

  &__list__last {
    margin-bottom: 30px;
  }

  &__topic__title {
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 10px;
    font-weight: bold;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      left: 15px;
      bottom: -5px; 
      width: 90%;
      height: 1px;
      background-color: #ccc; 
    }
  }

  &__list {
    padding-left: 20px;
    margin-bottom: 20px;

    &__element {
      margin-bottom: 5px;
      list-style-type: circle;
      margin-left: 20px;
      color: #808080;
      position: relative;
      display: flex; 
      justify-content: space-between; 
      align-items: center; 
      &::before {
        content: '•';
        position: absolute;
        left: -20px;
      }
      &::after {
        content: '';
        position: absolute;
        left: 15px;
        bottom: -5px; 
        width: 90%;
        height: 1px;
        background-color: #ccc; 
      }
    }
  }
  &__span {
    margin-left: auto;
  }

  .checkmark {
    float: right;
    color: green;
    font-weight: bold;
  }
}
