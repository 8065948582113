.Reviews {
  padding: 40px;
  
  &__title {
    font-size: 1.5em;
    margin-bottom: 20px;
    font-size: 26px;
    font-weight: 500;
    border-bottom: 2px solid #ddd;
    padding-bottom: 10px;
  }

  &__card {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    padding: 15px;
  }
  &__user {
    display: flex;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: space-between;
  }

  &__user_photo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }

  &__user_name {
    font-size: 1.2em;
    font-weight: bold;
    margin: 0;
    margin-top: 5px;
  }

  &__comment {
    font-size: 1em;
    margin: 0 50px;
    color: #808080;
  }

  &__rating {
    display: inline-flex;
    align-items: center;
    font-size: 1em;
    margin-top: 10px;
  }

  &__date {
    font-size: 0.9em;
    color: #777;
    text-align: right;
    margin-top: 10px;
    margin-left: 20px;

  }
}


.Reviews__star {
  margin-right: 5px;
  width: 20px;
  height: 20px;
}