.EmailConfirmationForm__input-group {
  display: flex;
  justify-content: space-between;
}

.EmailConfirmationForm__input {
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  margin: 5px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}