.CocktailsList {
  width: 75%;
  border: 2px solid #CCCCCC;
  margin: 30px;
  border-radius: 30px;

  &__table {
    width: 100%;
    margin: 20px;
    padding-right: 20px;
  }

  &__top {
    display: flex;
    justify-content: space-between;
  }

  &__header {
    border-bottom: 2px solid #CCCCCC;
  }

  &__add-button {
    background-color: black;
    color: white;
    height: 40px;
    width: 110px;
    margin: 20px;
    border-radius: 30px;
    font-weight: 500;
    font-size: 16px;
  }

  &__title {
    margin-left: 30px;
    margin-top: 30px;
    font-size: 26px;
    font-weight: 500;
  }

  &__icon {
    width: 20px;
    height: 20px;
    margin: 5px;
    cursor: pointer;
  }

  &__cocktail-image {
    width: 80px;
    height: 80px;
  }

  &__cocktail {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__row_item {
    padding-left: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  &__row_item2 {
    width: 100px;
  }

  &__table-header {
    padding-left: 20px;
    text-align: left;
    font-size: 20px;
    font-weight: 500;
  }
}

tbody {
  font-size: 18px;
  font-weight: 400;
}
