.CocatilSideBar {
    display: flex;
    flex-direction: column;
    gap: 15px;
    &__item {
      display: flex;
      align-items: center;
      gap: 20px;
    }
    &__img {
      width: 10vw;
      height: 10vw;
      border: 2px solid gray;
      border-radius: 30px;
    }
    &__title {
      font-weight: 500;
      font-size: 26px;
    }
    &__p {
      font-size: 20px;
      font-weight: 500;
    }
  }