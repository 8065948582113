.LoginForm {
  background-color: #fff;
  padding: 20px 20px 10px 20px;
  border-radius: 15px;
  width: 340px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;

  &__title {
    background-color: #333;
    color: #fff;
    padding: 10px 0;
    border-radius: 15px 15px 0 0;
    margin: -20px -20px 20px -20px;
    font-size: 1.2rem;
    position: relative;
  }

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__input-group {
    margin-bottom: 15px;
    text-align: left;
    position: relative;

    label {
      display: block;
      margin-bottom: 5px;
      font-weight: bold;
    }

    input {
      width: 100%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      box-sizing: border-box;
    }
  }

  &__input-password {
    position: relative;

    input {
      width: 100%;
      padding-right: 40px;
    }
  }

  &__toggle-password {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;

    img {
      width: 20px;
      height: 20px;
    }
  }

  &__checkbox-group {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    input {
      margin-right: 10px;
    }
  }

  &__submit {
    background-color: #333;
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;

    &:hover {
      background-color: #555;
    }
  }

  &__footer {
    margin-top: 10px;
    font-size: 0.875rem;
    color: #999;

    .LoginForm__forgot {
      display: block;
      color: #999;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .LoginForm__signup {
      color: #333;
      text-decoration: none;
      margin-left: 8px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.Lock {
  width: 0.6em;
}

.closeFormExpert {
  position: relative;
  top: -9px;
  right: -100px;
  cursor: pointer;
  font-size: 20px;
  color: white;
}