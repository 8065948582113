.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001; 
}

.modal-main {
  background: white;
  border-radius: 20px;
  max-width: 500px;
  width: 100%; 
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); 
  margin: 100px auto;
  position: relative;
  max-height: calc(100vh - 200px);
  overflow: auto;
}

.modal-header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #e5e5e5;
  border-radius: 20px 20px 0 0 ;
  padding: 10px;
  background-color: black;
}

.modal-header h2 {
  margin: 0;
  font-size: 1.5em;
  flex-grow: 1;
  text-align: center;
  color: #e5e5e5;
}

.close-button {
  position: absolute;
  right: 10px;
  top: 45%;
  transform: translateY(-50%);
  font-size: 1.5em;
  cursor: pointer;
  color: #e5e5e5;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}
